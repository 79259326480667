.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.div_error {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px; /* Ajoutez ceci si vous voulez limiter la largeur de votre div */
    padding: 20px;
    border: 1px solid #ccc; /* Ajoutez ceci si vous voulez un contour */
    background-color: #f9f9f9; /* Ajoutez ceci si vous voulez une couleur de fond */
}
