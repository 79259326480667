.footer {
    background-color: #054270;
    color: #fff; /* Couleur du texte */
    text-align: center;
    padding: 20px 0;
    height: 30px;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }


  
  