.Home_page{
    margin-top: 200px;
    width: 100%;
}

h1{
  font-size: 2.1em !important;
}

.Titles {
    text-align: center; /* Centre le texte sur tous les écrans */
    background-color: #054270;
    color: white;
    padding: 30px 0;
    margin: 20px 0;
  }

  .presentation {
    max-width: 800px;
    margin: 0 auto; /* Pour centrer la div principale */
    padding: 15px;
    display: flex;
    justify-content: space-between; /* Les éléments se répartissent horizontalement */
    flex-wrap: wrap; /* Si la largeur devient trop petite, passez à la ligne suivante */
    text-align: justify;
}

.content {
    width: 48%; /* Ajustez la largeur pour laisser de l'espace entre les deux éléments */
}

.subtitles {
    color: #ff4757;
    margin: 30px 0;
}

.container_carousel{
  display: flex;
  justify-content: center;
}


.img_map{
  display: flex;
  justify-content: center;
}

.img_map > img{
  width: 1000px;
  border-radius: 10px;
}

  .text_intervention {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    width: 50%;
    margin: 20px auto; /* Ajout de cette ligne pour centrer le conteneur */

  }

.commune_name{
  color: #ff4757;
}

/* Présentation vidéos */

.Titre_presentation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin: 20px 0;
  gap: 10px;
  width: 100%;
}

.Titre_presentation_1{
  color: #ff4757;
}

.Titre_presentation_2{
  color: #054270;
}

.services{
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: space-between;
  padding: 0 3%;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-item > h2 {
  color: #054270;
  font-size: 20px;
}

.services_logo{
  height: 150px;
  width: 150px;
  background: linear-gradient(30deg, #054270 0%, #054270 30%, #ff4757 50%, #ff4757 100% );
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services_logo > img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(68deg) brightness(101%) contrast(104%);
  height: 100px;
  width: auto;
}

/* vidéos structures */
.section_video{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin: 30px 0;
}


.section_card {
    display: flex;
    flex-wrap: wrap; /* Permet aux cartes de passer à la ligne sur des écrans plus petits */
    justify-content: center;
    padding: 0 10px; /* Ajout de marge sur les côtés pour les écrans plus petits */
  }

  .card {
    border: solid 0.5px rgb(154, 154, 154);
    width: 300px;
    height: 450px;
    max-width: 100%; /* Ajuste la largeur à 100% sur des écrans plus petits */
    box-sizing: border-box;
    margin: 10px;
    border-radius: 17px;
    overflow: hidden; 
  }

.card_title{
    background-color: #054270;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 50px;
}

.card_price{
    /* background-color: #f1c40f; */
    background-color: #ff4757;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    height: 50px;
}

.card_info {
    padding-top: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    gap: 40px;
  }

  .card_info > p{
    font-size: 0.9em;
  }

  .explication{
    font-size: 0.8em !important;
  }

  .advise{
    padding: 30px 0;
display: grid;
justify-content: center;
  }

  .red_card{
    border: solid 3px red;
    width: 250px;
    height: auto;
    padding: 10px;
  }
  .red_card > h3 {
    font-style: italic;
  }

.sec_contact{
  display: flex;
  justify-content: space-around;
  padding: 20px 20%;
}


.card_contact{
  
  box-sizing: border-box;
  width: 190px;
  height: 200px;
  background: rgba(217, 217, 217, 0.58);
  border: 1px solid white;
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bolder;
  color: black;
}

.card_contact:hover{
  border: 1px solid black;
  transform: scale(1.05);
}

.card_contact:active{
  border: 1px solid black;
  transform: scale(0.95) rotate(1.7deg);
}
.contact{
  gap: 5px;
}

a{
  color: #ff4757;
}

.svg_size2{
  width: 50px;
  color: white;
}



/* media query zone */

@media (max-width: 1200px) {
    .card {
      width: 300px;
      height: 450px;
      justify-content: center;/* Ajuste la largeur à 100% sur des écrans plus petits */
    }
  }
  
  @media (max-width: 768px) {
    .section_card {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap; 
    }
    .card_info{
      gap: 25px;
    }
    .card{
      width: 350px;
      height: 400px;
    }
    .content {
      width: 100%; /* Sur les petits écrans, le contenu occupera toute la largeur */
      margin-bottom: 20px; /* Ajoute un espacement entre les éléments */
  }
  .Image_pub > img {
    max-width: 100% ;
  }

  .img_map > img{
    width: 80%;
  }

  .sec_contact {
    align-items: center; /* Centrer les éléments dans la colonne */
    padding: 20px 5%; /* Ajustement de l'espacement sur les côtés */
  }

  .card_contact {
    width: 150px; /* Prend toute la largeur disponible */
    height: 150px;
    margin-bottom: 20px; /* Espace entre les cartes de contact */
  }

  .contact > button {
    width: 60px; /* Ajustement de la taille du bouton */
    height: 60px; /* Ajustement de la taille du bouton */
    font-size: 12px; /* Taille de la police pour le bouton */
  }

  .services{
    justify-content: space-around;
    gap: 5px;
  }

  .services_logo{
    width: 120px;
    height: 120px;
  }

  video{
    width: 80%;
  }

  }

  @media (max-width: 480px) {

p{
  text-align: start;
}

.text_intervention{
  text-align: start;
}

    .card_contact {
      padding: 20px; /* Ajustement de l'espacement à l'intérieur de la carte */
    }

    .card{
      width: 300px;
      height: 400px;
    }

    .sec_contact{
      gap: 10px;
    }
  
    .contact > button {
      width: 50px; /* Ajustement de la taille du bouton pour les petits écrans */
      height: 50px; /* Ajustement de la taille du bouton pour les petits écrans */
      font-size: 10px; /* Taille de la police pour le bouton sur les petits écrans */
    }

    .Titre_presentation{
      font-size: 75%;
    }

    .services{
      justify-content: space-around;
      gap: 5px;
    }

    .img_map > img{
      width: 100%;
    }

    .services_logo{
      width: 120px;
      height: 120px;
    }

  video{
    width: 100%;
    height: auto;
  }

  .explication{
    font-size: 0.9em !important;
  }

  .services_logo > img {
    height: 80px;
    width: auto;
  }

  }