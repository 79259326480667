.div_button {
  width: 100px;
  position: fixed;
  right: 0;
  top: 40%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.div_button_2 {
  width: 100px;
  position: fixed;
  right: 0;
  top: 60%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.div_button > i {
  color: red;
}

.div_button_2 > i {
  color: red;
}

.whatsapp-button > img {
  width: 100px;
}

.call_button > img {
  width: 50px;
}

@media screen and (max-width: 600px) {
  .whatsapp-button {
    right: 0;
  }

  .whatsapp-button > img {
    width: 70px;
  }
}

@media screen and (max-width: 400px) {
  .whatsapp-button > img {
    width: 60px;
  }

  .call_button > img {
    width: 60px;
  }

  .div_button_2 {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px; /* Adjust the height as needed */
    background-color: #ff4757; /* Adjust the background color as needed */
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    z-index: 20;
    top:auto
  }

  .call_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .call_button > img {
    width: 30px;
  }

  .div_button_2 > i {
    display: none; /* Hide the text on small screens */
  }
}
