.Nav_bar{
    width: 100%;
    position: fixed;
    top: 0 ;
    z-index: 3; /* Assure que la barre de navigation est au-dessus du reste du contenu */
    box-sizing: border-box;
    border-bottom: solid 3px #054270;
}

/* première partie de la nav-bar */

.svg_size {
    width: 20px;
}

.nav_info{
    background-color: #054270;
    height:20%;
}

.nav_info > ul {
    padding: 4px 0px 4px 0px;
    gap: 10%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0;
}

.nav_info > ul > li {
    color: white;
}

.nav_info> ul > li > a {
color : white;
}

/* .Nav_info > ul > li > a::hover {
    color: black;
} */

/* deuxième partie de la nav-bar */

.Nav_2{
    display: flex;
    align-items: center;
    padding: 0 10%;
    justify-content: space-around;
    background-color: white;
    height: 120px;
}

.logo_box > img{
    width: 150px;
}

.nav_links > ul{
    gap: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.nav_links a{
    text-decoration: none;
    color: #054270;
    transition: color 0.3s, transform 0.3s;
}

.nav_links a:hover {
    color: #ff4757; /* Couleur au survol */
    transform: scale(1.5); /* Léger zoom au survol */
  }

/* responsive part */

@media only screen and (max-width: 600px) {
    .Nav_bar {
        flex-direction: column;
        border-bottom: solid 1px #054270;
        padding-bottom: 10px;
        position: static;
        top: 0 !important;
    }

    .nav_info {
        height: auto;
    }

    .nav_info > ul {
        flex-direction: column;
        align-items: center;
    }

    .Nav_2 {
        flex-direction: column;
        height: auto;
    }

    .logo_box > img{
        width: 200px;
    }

    .nav_links > ul {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }

    .Home_page{
        margin-top: 0;
    }
}