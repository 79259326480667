.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    width: 800px;
    height: auto;
  }
  
  .slide {
    display: none;
    position: relative;
  }
  
  .slide.active {
    display: block;
  }
  
  .slide-image {
    width: 800px;
    height: 600px;
  }
  
  .overlay {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%; /* Nouvelle ligne */
    max-height: 100%; /* Nouvelle ligne */
    background-color: rgba(0, 0, 0, 0.584); /* Filtre sombre */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 5px;
  }
  
  .overlay h2 {
    font-size: 24px;
  }
  
  .hr_carousel {
    width: 70px;
    border: solid 1px #ff4757;
  }
  
  .overlay button {
    padding: 10px 20px;
    background-color: #ff4757;
    color: #fff;
    border-radius: 30em;
    border: none;
    cursor: pointer;
  }
  
  .overlay button a {
    color: white; /* Couleur du texte en blanc */
  }

/* responsive part */

  @media only screen and (max-width: 768px) {

    .slide-image {
      width: 100%;
      height: auto;
    }

    .hr_carousel {
        width: 50px !important ;
        border: solid 1px #ff4757;
      }
  
    .overlay {
      width: 100% !important;
      margin: 0;
    }
  
    .overlay h2 {
      font-size: 20px;
    }
  
    .overlay button {
      padding: 8px 16px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .overlay h2 {
      font-size: 18px;
    }
  
    .hr_carousel {
      width: 40px;
    }
  
    .overlay button {
      padding: 6px 12px;
    }
  }

/* Media queries pour rendre le carrousel responsive */

/* Pour les petits écrans jusqu'à 480px (par exemple, smartphones en mode portrait) */
@media only screen and (max-width: 480px) {
    .carousel {
      display: block; /* Afficher les slides les unes en dessous des autres */
    }
  
    .slide-image {
      width: 100%; /* Largeur maximale de l'image */
      height: auto; /* Hauteur automatique */
      object-fit: cover; /* Ajustement de l'image pour remplir complètement le conteneur */
    }
  
    .overlay {
      width: 100%; /* Largeur de l'overlay à 100% */
    }
  
    .overlay h2 {
      font-size: 16px; /* Ajustement de la taille de police, 10% plus petit */
    }
  
    .overlay h3,
    .overlay h4 {
      font-size: 14px; /* Ajustement de la taille de police, 10% plus petit */
    }
  
    .hr_carousel {
      width: calc(100% - 40px); /* Ajustement de la largeur de la barre horizontale */
    }
  
    .overlay button {
      padding: 6px 12px; /* Ajustement du padding du bouton */
      font-size: 12px; /* Ajustement de la taille de police du bouton, 10% plus petit */
    }
  }
  
  /* Pour les écrans moyens à grands jusqu'à 768px (par exemple, smartphones en mode paysage et tablettes) */
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    .carousel {
      display: block; /* Afficher les slides les unes en dessous des autres */
    }
  
    .slide-image {
      max-width: 100%; /* Largeur maximale de l'image */
      height: auto; /* Hauteur automatique */
      object-fit: cover; /* Ajustement de l'image pour remplir complètement le conteneur */
    }
  
    .overlay {
      width: 100%; /* Largeur de l'overlay à 100% */
    }
  
    .overlay h2 {
      font-size: 18px; /* Ajustement de la taille de police */
    }
  
    .overlay h3,
    .overlay h4 {
      font-size: 16px; /* Ajustement de la taille de police */
    }
  
    .hr_carousel {
      width: calc(100% - 50px); /* Ajustement de la largeur de la barre horizontale */
    }
  
    .overlay button {
      padding: 8px 16px; /* Ajustement du padding du bouton */
      font-size: 14px; /* Ajustement de la taille de police du bouton */
    }
  }
  